export const motivosVisitas = {
    "Aluno de outra unidade que vem fazer atividade extra": "recorrente",
    "Buscar Aluno - responsável que precisa entrar na escola para buscar o aluno (van, avô, tio, etc)": "recorrente",
    "Colaborador registrado em outra unidade": "esporadico",
    "Colaborador Terceiro - não está registrado no RM mas trabalha na unidade (limpeza, cozinha, etc)": "recorrente",
    "Conhecer a Escola - Lead": "esporadico",
    "Prestador de serviços gerais - manutenção, instalação, etc": "esporadico",
    "Outro Motivo": "recorrente",
};

export default motivosVisitas;