import React, { Fragment, SyntheticEvent, useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from "@mui/material/CircularProgress";
import { Divider, Grow, Grid, Tab, Stepper, Step, StepLabel, Button, Typography, Stack, Box, Select, MenuItem, TextField, Skeleton } from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import * as _cursoService from "../../service/curso-service";
import * as _controllerService from "../../service/guardian-controller-service";
import * as _ruleService from "../../service/guardian-rule-service";
import * as _aiService from "../../service/ai-service";
import * as _yearService from "../../service/year-service";
import { Intervals } from "../../types/guardian-types";
import { RuleType } from "../../service/guardian-rule-service";
import { pt } from 'yup-locale-pt';
import { useFormSteps } from '../../hooks/use-FormStep';
import HorarioComponent from "../../components/HorarioComponent";
import HorarioEstaticoComponent from "../../components/HorarioEstaticoComponent";
import { ExibeMensagem } from "../../components/shared/Message";
import csatList from "../../utils/csatList";
import { Unidade } from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import { AuthContext } from '../../context/AuthContext';
import CustomTable from '../../components/CustomTable';
import { selectedUnidadeState } from "../../recoil/atoms/selectedUnidadeState";
import withUnidade from "src/hocs/withUnidade";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InfoComponent from '../../components/InfoComponent';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDebounce } from "src/hooks/use-debounce";
import { styled } from '@mui/material/styles';
import { useRecoilValue } from "recoil";
import { useTheme } from "@mui/material/styles";


Yup.setLocale(pt);

const validationSchema = Yup.object({
})

interface Props {
    Unidade: Unidade
    handleShowCsat: (key: string) => void
}

const object = {
}

interface GroupControllerFromUnit {
    id: number;
    uId: string;
    name: string;
    unitCrmId: string;
}

interface Service {
    id: string;
    curso_Name: string;
}

interface GroupControllerResponse {
    succeeded: boolean;
    errors: any;
    data: GroupControllerFromUnit[];
}

type TabsType = "Unidade" | "Servico";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
}));



const PermissaoHorario = (props: Props) => {
    const {
        Unidade,
        handleShowCsat
    } = props;
    const [loadingServices, setLoadingServices] = useState(false);
    const yearNow = new Date().getFullYear();
    const [anoLetivo, setAnoLetivo] = useState(yearNow.toString());
    const selectedUnidade = useRecoilValue(selectedUnidadeState)
    const [nomeServico, setNomeServico] = useState('');
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const steps = ['Selecionar o Serviço', 'Ajustar o Horário'];
    const [loadingControllers, setLoadingControllers] = useState(true);
    const [loadingHorarios, setLoadingHorarios] = useState(true);
    const [services, setServices] = useState([]);
    const [groupControllersFromUnit, setGroupControllersFromUnit] = useState<GroupControllerFromUnit[]>([]);
    const [selectedService, setSelectedService] = useState<Service>(null);
    const [selectedEntryController, setSelectedEntryController] = useState<number[]>()
    const [selectedExitController, setSelectedExitController] = useState<number[]>()
    const [iaGenerativeText, setIaGenerativeText] = useState<string>('');
    useDebounce(() => generateAiHorario(), 3000, iaGenerativeText);
    const [segmentos, setSegmentos] = useState([]);
    const [selectedSegmento, setSelectedSegmento] = useState<string>('');
    const [isLoadingSegmento, setIsLoadingSegmento] = useState(false);

    const [entryRule, setEntryRule] = useState<RuleType>();
    const [exitRule, setExitRule] = useState<RuleType>();
    const [rulesByUnit, setRulesByUnit] = useState([]);
    const [tabValue, setTabValue] = useState<TabsType>("Unidade");



    const [entryTime, setEntryTime] = useState<Intervals[]>([]);
    const [exitTime, setExitTime] = useState<Intervals[]>([]);
    const [saving, setSaving] = useState(false);
    const [isTocreateEntry, setIsTocreateEntry] = useState(false);
    const [isTocreateExit, setIsTocreateExit] = useState(false);

    const clearState = () => {
        setIaGenerativeText('');
        setEntryRule(null);
        setExitRule(null);
        setEntryTime([]);
        setExitTime([]);
        setSelectedEntryController([]);
        setSelectedExitController([]);
    }
    
    const generateAiHorario = async () => {
        if (!iaGenerativeText || iaGenerativeText === '')
        return;
        try {
            setLoadingHorarios(true);
            const request = {
                use_cache: true,
                message: iaGenerativeText,
                json_response: true
            };
            const { entry, exit } = await _aiService.generateAiInterval(request);
            setEntryTime(entry);
            setExitTime(exit);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingHorarios(false);
        }
    }

    const handleSelect = async (row, event = null) => {
        event?.preventDefault();
        event?.stopPropagation();
        clearState();
        setIsTocreateEntry(false);
        setIsTocreateExit(false);
        setLoadingControllers(true);
        setLoadingHorarios(true);
        setSelectedService(row);
        try {
            const rowId = row.id;
            var rules = await _ruleService.GetRulesByUnitAndService(Unidade?.id, rowId);
            if (!rules?.data || rules?.data.length === 0) {
                setIsTocreateEntry(true);
                setIsTocreateExit(true);
                return;
            }
            const entryRuleId = rules?.data[0]?.uId;
            var entryTime = entryRuleId ? await _ruleService.GetRule(entryRuleId) : null;
            if (entryTime) {
                setEntryRule(entryTime?.data);
                setEntryTime(entryTime?.data?.intervals);
                const defaultEntryControllers = groupControllersFromUnit
                    .filter(group =>
                        entryTime?.data?.ruleControllerGroup?.map(rule => rule.controllerGroupId).includes(group.id)
                    )
                    .map(group => group.id);
                setSelectedEntryController(defaultEntryControllers);
            } else {
                setIsTocreateEntry(true);
                ExibeMensagem("O serviço não possui horário cadastrado para o período de Entrada", "1", true);
            }

            const exitRuleId = rules?.data[1]?.uId;
            var exitTime = exitRuleId ? await _ruleService.GetRule(exitRuleId) : null;
            if (exitTime) {
                setExitRule(exitTime?.data);
                setExitTime(exitTime?.data?.intervals);
                const defaultExitControllers = groupControllersFromUnit
                    .filter(group =>
                        exitTime?.data?.ruleControllerGroup.map(rule => rule.controllerGroupId).includes(group.id)
                    )
                    .map(group => group.id);
                setSelectedExitController(defaultExitControllers);
            } else {
                setIsTocreateExit(true);
                ExibeMensagem("O serviço não possui horário cadastrado para o período de Saída", "1", true);
            }
        } catch (err) {
            setIsTocreateEntry(true);
            setIsTocreateExit(true);
            ExibeMensagem("Ocorreu um erro ao carregar os dados. Por favor, tente novamente mais tarde.", "1", true);
        } finally {
            changeStep(currentStep + 1);
            setLoadingControllers(false);
            setLoadingHorarios(false);
        }
    }
    const tableConfigs = {
        selection: false,
        editing: false,
        isEndedList: true,
        badges: true,
        haveFilter: true,
        sorted: true,
        actions: [
            (row) => {
                return {
                    id: "select",
                    label: <div className='staticAction'><HowToRegIcon /> Selecionar</div>,
                }
            }
        ],
        onClickAction: (action, row, event) => {
            switch (action.id) {
                case "select":
                    handleSelect(row, event);
                    break;
                default:
                    console.warn("Unhandled option: ", action);
                    break;
            }
        },
    }
    const getRulesByUnit = async (unidadeId) => {
        var rules = await _ruleService.GetRulesByUnit(unidadeId);
        setRulesByUnit(rules?.data);
        setLoadingHorarios(true);
        setIsTocreateEntry(false);
        setIsTocreateExit(false);

        const entryTimeIds = rules?.data.filter(rule => rule.name.toLowerCase().includes('entrada') && rule.name.toLowerCase().includes('aluno') && !rule.serviceCrmId).map(rule => rule.uId);
        const exitTimeIds = rules?.data.filter(rule => (rule.name.toLowerCase().includes('saida') || rule.name.toLowerCase().includes('saída')) && rule.name.toLowerCase().includes('aluno') && !rule.serviceCrmId).map(rule => rule.uId);

        if (entryTimeIds.length === 0) {
            setIsTocreateEntry(true);
        }

        if (entryTimeIds.length > 0) {
            let entryRules = [];
            for (let i = 0; i < entryTimeIds.length; i++) {
                let entryTime = await _ruleService.GetRule(entryTimeIds[i]);
                entryRules.push(entryTime?.data);
            }
            if (entryRules && entryRules.length > 0) {
                setEntryTime(entryRules[0]?.intervals);
            }
        }

        if (exitTimeIds.length === 0) {
            setIsTocreateExit(true);
        }
        if (exitTimeIds.length > 0) {
            let exitRules = [];
            for (let i = 0; i < exitTimeIds.length; i++) {
                let exitTime = await _ruleService.GetRule(exitTimeIds[i]);
                exitRules.push(exitTime?.data);
            }
            if (exitRules && exitRules.length > 0) {
                setExitTime(exitRules[0]?.intervals);
            }
        }

        setLoadingHorarios(false);
    }

    const formik = useFormik({
        initialValues: object,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                if (saving) return;

                setSaving(true);
                if (!selectedEntryController) {
                    setSaving(false);
                    ExibeMensagem("Não foi possível encontrar o grupo de Controlladores para a regra de entrada", "2", true);
                    return;
                }
                if (!selectedExitController) {
                    setSaving(false);
                    ExibeMensagem("Não foi possível encontrar o grupo de Controlladores para a regra de saída", "2", true);
                    return;
                }
                if (isTocreateEntry)
                    entryTime.map(a => delete a.id)
                const entryRequest = entryRule ? {
                    uId: entryRule?.uId,
                    name: entryRule?.name,
                    unitCrmId: entryRule?.unitCrmId,
                    serviceCrmId: entryRule.serviceCrmId,
                    intervals: entryTime,
                    controllerGroupId: selectedEntryController,
                    visitId: []
                } : {
                    name: selectedService.curso_Name + " - Entrada",
                    unitCrmId: Unidade.id,
                    serviceCrmId: selectedService.id,
                    intervals: entryTime,
                    controllerGroupId: selectedEntryController,
                    visitId: []
                }

                let resp = null;
                if (isTocreateEntry) {
                    resp = await _ruleService.createRule(entryRequest);
                    setEntryRule(resp.data);
                } else {
                    resp = await _ruleService.updateRule(entryRequest);
                    setEntryRule(resp.data);
                }
                if (isTocreateExit)
                    exitTime.map(a => delete a.id)
                const exitRequest = exitRule ? {
                    uId: exitRule?.uId,
                    name: exitRule?.name,
                    unitCrmId: exitRule?.unitCrmId,
                    serviceCrmId: exitRule.serviceCrmId,
                    intervals: exitTime,
                    controllerGroupId: selectedExitController,
                    visitId: []
                } : {
                    name: selectedService.curso_Name + " - Saída",
                    unitCrmId: Unidade.id,
                    serviceCrmId: selectedService.id,
                    intervals: exitTime,
                    controllerGroupId: selectedExitController,
                    visitId: []
                }

                if (isTocreateExit) {
                    resp = await _ruleService.createRule(exitRequest);
                    setExitRule(resp.data);
                } else {
                    resp = await _ruleService.updateRule(exitRequest);
                    setExitRule(resp.data);
                }
                setSaving(false);
                changeStep(0);
                ExibeMensagem("Horário atualizado com sucesso", "0", true);
            } catch (err) {
                setSaving(false);
            } finally {
                if (handleShowCsat) handleShowCsat(csatList.cadastroHorario)
            }
        }
    });

    const handleTabsChange = (event: SyntheticEvent<any>, value: string): void => {
        event.preventDefault();
        event.stopPropagation();
        clearState();
        if (value === "Unidade") {
            setLoadingHorarios(true);
            if (Unidade && Unidade.id)
                getRulesByUnit(Unidade.id);
            setTabValue("Unidade");
            changeStep(0);
        } else if (value === "Servico") {
            setTabValue("Servico");
        } else {
            if (Unidade && Unidade.id)
                getRulesByUnit(Unidade.id);
            setTabValue("Unidade");
        }
    }

    const mapSegmentos = (resp) => {
        return resp.map((service) => {
            if (service.segmento_Id && service.segmento_Name) {
                return { id: service.segmento_Id, nome: service.segmento_Name };
            } else {
                return null;
            }
        }).filter(segmento => segmento !== null)
            .filter((segmento, index, self) =>
                index === self.findIndex((t) => (
                    t.id === segmento.id
                ))
            );
    }

    const fetchSegmentos = async (anoLetivo) => {
        try {
            setIsLoadingSegmento(true);
            setLoadingServices(true);
            const value = await _yearService.GetYear(anoLetivo);
            const resp = await _cursoService.ListOnlyByIdUnidade(Unidade?.id, value?.id ?? null, null, null, true);
            const segmentos = mapSegmentos(resp);
            if (segmentos && segmentos.length > 0 && !segmentos.find(segmento => segmento.id === selectedSegmento)) {
                setSelectedSegmento('');
            }
            setSegmentos(segmentos);
            setServices(resp);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingServices(false);
            setIsLoadingSegmento(false);
        }
    }

    const getServices = async () => {
        try {
            setLoadingServices(true);
            const value = await _yearService.GetYear(anoLetivo);
            if (!segmentos.length && value) {
                try {
                    setIsLoadingSegmento(true);
                    const resp = await _cursoService.ListOnlyByIdUnidade(Unidade?.id, value?.id, null, null, true);
                    const segmentos = mapSegmentos(resp);
                    setSegmentos(segmentos);
                    setServices(resp);
                } catch (err) {
                    console.error(err)
                } finally {
                    setIsLoadingSegmento(false);
                }
            } else if (value) {
                const resp = await _cursoService.ListOnlyByIdUnidade(Unidade?.id, value?.id, null, null, true);
                setSegmentos(mapSegmentos(resp));
                setServices(resp);
            } else {
                setSegmentos([]);
                setServices([]);
                ExibeMensagem("Não foi possível encontrar os serviços", "1", true);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingServices(false);
        }
    }
    const getControllersGroupInUnit = async () => {
        try {
            if ((!Unidade || Unidade.id) && (!selectedUnidade || !selectedUnidade.id))
                throw new Error("Unidade não encontrada");
            const resp: GroupControllerResponse = await _controllerService.GetControllersGroupInUnit(Unidade.id ?? selectedUnidade.id);
            if (resp) setGroupControllersFromUnit(resp.data);
            else ExibeMensagem("Não foi possível encontrar os controllers da unidade", "0", true);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingControllers(false);
        }
    }

    const rootInputStyles = {
        '&:focus-within fieldset, &:focus-visible fieldset': {
            boxShadow: 'none !important',
        },
    };

    const filterRuleByService = (service) => {
        const matchedRule = rulesByUnit.find(rule => rule.serviceCrmId === service.id);
        if (matchedRule) {
            return {
                ...service,
                badge: {
                    label: "Com regra",
                    color: "success"
                },
                haveRule: true
            };
        } else {
            return {
                ...service,
                badge: {
                    label: "Sem regra",
                    color: "default"
                },
                haveRule: false
            };
        }
    }

    useEffect(() => {
        getRulesByUnit(Unidade.id);
        getServices();
        getControllersGroupInUnit();
    }, [Unidade]);


    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            getServices();
        }
    };


    const handleSave = (intervals: Intervals[], isEntry: boolean) => {
        if (isEntry)
            setEntryTime(intervals);
        else
            setExitTime(intervals);
    }

    const filteredServices = services.filter((service) => {
        if (nomeServico && !service.nome.includes(nomeServico)) {
            return false;
        }
        if (selectedSegmento && service.segmento_Id !== selectedSegmento) {
            return false;
        }
        return true;
    });

    const formComponent = [
        <>
            <Stack
                spacing={3}
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h6'>Pesquisar</Typography>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}>
                        <Select
                            fullWidth
                            name="anoLetivo"
                            value={anoLetivo}
                            onChange={(e) => {
                                setAnoLetivo(e?.target.value)
                                fetchSegmentos(e?.target.value.toString())
                            }}
                            displayEmpty
                            sx={{
                                '& legend': {
                                    display: 'none'
                                }
                            }}
                        >
                            <MenuItem value="">
                                <em>Selecione o Ano letivo</em>
                            </MenuItem>
                            {Array.from({ length: 2 }, (_, i) => yearNow + i).map((year) => (
                                <MenuItem key={year} value={String(year)}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Select
                            fullWidth
                            name="selectedSegmento"
                            variant='outlined'
                            value={isLoadingSegmento ? "" : selectedSegmento}
                            onChange={(e) => setSelectedSegmento(e.target.value)}
                            displayEmpty
                            sx={{
                                '& legend': {
                                    display: 'none'
                                }
                            }}
                            disabled={isLoadingSegmento || (loadingServices && !segmentos.length)}
                        >
                            {isLoadingSegmento || (loadingServices && !segmentos.length) ?
                                <MenuItem value="">
                                    <CircularProgress color="primary" size={20} />
                                </MenuItem>
                                :
                                <MenuItem value="">
                                    <em>Todos os Segmentos</em>
                                </MenuItem>
                            }
                            {!isLoadingSegmento && segmentos.length > 0 && segmentos.map((choice) => (
                                <MenuItem key={choice.id} value={choice.id}>
                                    {choice.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={4}>
                        <TextField
                            fullWidth
                            name="nomeServico"
                            label="Nome do Serviço"
                            value={nomeServico}
                            onChange={(e) => setNomeServico(e.target.value)}
                            variant="outlined"
                            InputProps={{
                                sx: {
                                    ...rootInputStyles,
                                },
                            }}
                            inputProps={{
                                maxLength: 25
                            }}
                            onKeyUp={handleEnterKeyPress}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <AuthContext.Consumer key={'initial'}>
                {({ appContext }) => {
                    const { tables } = appContext || {}
                    const matriculasColumns =
                        tables['listservicos-table']?.columns || []
                    return (
                        <CustomTable
                            columns={matriculasColumns}
                            data={filteredServices.map((service) => {
                                return filterRuleByService(service);
                            }).sort((a) => a.haveRule ? -1 : 1) || []}
                            selected={[]}
                            onEditRow={handleSelect}
                            configs={tableConfigs}
                            isLoading={loadingServices}
                            staticAction={true}
                        />
                    )
                }}
            </AuthContext.Consumer>
        </>,
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack
                  direction={"column"}
                  spacing={3}
                  sx={{
                    mb: 3
                  }}
                >
                  <Typography variant="h6">
                    Descreva os dias e horários que serão liberados
                  </Typography>
                  <TextField
                    fullWidth
                    label="De segunda a sexta das 06:00 as 18:00"
                    name="iaGenerativeText"
                    size="small"
                    multiline
                    rows={4}
                    onChange={(e) => setIaGenerativeText(e.target.value)}
                    value={iaGenerativeText}
                  />
                </Stack>
                <HorarioComponent
                    entryTime={entryTime}
                    exitTime={exitTime}
                    loadingHorarios={loadingHorarios}
                    handleSave={handleSave}
                />
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SendIcon />}
                        type="submit"
                        disabled={saving}
                    >
                        {saving ? 'Salvando' : 'Salvar'}
                    </Button>
                </Stack>
            </form>
        </>
    ]
    const { currentStep, currentComponent, changeStep } = useFormSteps(formComponent);
    return (
        <Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                }}
            >

                <TabContext value={tabValue}>
                    <Stack
                        spacing={3}
                        sx={{ mb: 3 }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                mb: 3
                            }}
                        >
                            Permissão de Horário
                        </Typography>
                        <TabList
                            indicatorColor="primary"
                            onChange={(event, value) => handleTabsChange(event, value)}
                            scrollButtons={false}
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab
                                label='Por Unidade'
                                value="Unidade"
                            />
                            <Tab
                                label='Por Serviço'
                                value="Servico"
                            />
                        </TabList>
                        <Divider />
                    </Stack>

                    <TabPanel value="Unidade"
                        sx={{
                            paddingRight: 0,
                            paddingLeft: 0
                        }}
                    >
                        <Stack spacing={3}>
                            <Stack
                                spacing={4}
                                useFlexGap
                                flexWrap="wrap"
                            >
                                <Grid
                                    container
                                    spacing={4}
                                    width="100%"
                                    justifyContent="center"
                                    sx={{
                                        marginLeft: 0,
                                        marginTop: 0
                                    }}
                                >
                                    <Grid
                                        container
                                        width="100%"
                                        justifyContent="center"
                                    >
                                        <Grow
                                            in
                                            style={{ transformOrigin: '0 0 0' }}
                                            {...{ timeout: 1000 }}
                                        >
                                            <Stack
                                                spacing={3}
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ width: "100%" }}
                                            >
                                                {loadingControllers || loadingHorarios ? (
                                                    <Skeleton variant="rectangular" width="100%" height={200} />
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            minHeight: 200
                                                        }}
                                                    >
                                                        <InfoComponent
                                                            title="Regra padrão da unidade"
                                                            desc="Por padrão, todos os alunos seguem as regras gerais de entrada e saída definidas pela unidade. Caso necessário, você pode configurar regras específicas para um serviço, e essas regras serão aplicadas apenas aos alunos inscritos nesse serviço. Quando o serviço não tiver uma regra específica, será utilizada a regra padrão da unidade. <br> &#9888; <strong>Observação:</strong> Esta tela é exclusiva para configurar regras de alunos. Para ajustes relacionados a responsáveis ou colaboradores, entre em contato com a equipe de TI."
                                                            type="info"
                                                        />
                                                    </Box>
                                                )}
                                                {loadingControllers || loadingHorarios ? (
                                                    <Skeleton variant="rectangular" width="100%" height={300} />
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            minHeight: 200
                                                        }}
                                                    >
                                                        <HorarioEstaticoComponent
                                                            unitControllers={groupControllersFromUnit}
                                                            entryTime={entryTime}
                                                            exitTime={exitTime}
                                                            loadingControllers={loadingControllers}
                                                            loadingHorarios={loadingHorarios}
                                                        />
                                                    </Box>
                                                )}
                                            </Stack>
                                        </Grow>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                    </TabPanel>
                    <TabPanel
                        value="Servico"
                        sx={{
                            paddingRight: 0,
                            paddingLeft: 0
                        }}
                    >
                        <Stack
                            spacing={3}
                            sx={{ mb: 3 }}
                        >
                            <Grow
                                in
                                style={{ transformOrigin: '0 0 0' }}
                                {...{ timeout: 1000 }}
                            >
                                <Stack spacing={3}>
                                    <Stack
                                        spacing={4}
                                        useFlexGap
                                        flexWrap="wrap"
                                        justifyContent="center"
                                        alignItems="center"
                                        position="relative"
                                        sx={{
                                            minHeight: 200,
                                            backgroundColor: 'var(--bs-gray-200)',
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Stack
                                            spacing={2}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                mt: isSmall ? 6 : 3,
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                textAlign="center"
                                                sx={{ px: 2 }}
                                            >
                                                Gerenciamento das Regras das Catracas
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                textAlign="center"
                                                sx={{ px: 2 }}
                                            >
                                                Selecione o serviço em que deseja gerenciar as regras de horário
                                            </Typography>
                                        </Stack>
                                        {isSmall ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexGrow: 1,
                                                    gap: { xs: 5, md: 'none' },
                                                }}
                                            >
                                                <Stepper
                                                    id="desktop-stepper"
                                                    activeStep={currentStep}
                                                    orientation="vertical"
                                                    connector={<QontoConnector />}
                                                    sx={{
                                                        width: '100%',
                                                        pb: 2
                                                    }}
                                                >
                                                    {steps.map((label) => (
                                                        <Step
                                                            sx={{
                                                                ':first-child': { pl: 0 },
                                                                ':last-child': { pr: 0 },
                                                            }}
                                                            key={label}
                                                        >
                                                            <StepLabel>{label}</StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexGrow: 1,
                                                    minWidth: '80%',
                                                    gap: { xs: 5, md: 'none' },
                                                }}
                                            >
                                                <Stepper
                                                    id="desktop-stepper"
                                                    activeStep={currentStep}
                                                    connector={<QontoConnector />}
                                                    sx={{
                                                        width: '100%',
                                                        height: 40,
                                                    }}
                                                >
                                                    {steps.map((label) => (
                                                        <Step
                                                            sx={{
                                                                ':first-child': { pl: 0 },
                                                                ':last-child': { pr: 0 },
                                                            }}
                                                            key={label}
                                                        >
                                                            <StepLabel>{label}</StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                            </Box>
                                        )}
                                        <Button
                                            disabled={currentStep === 0}
                                            color="primary"
                                            type="button"
                                            sx={{
                                                position: 'absolute',
                                                top: 10,
                                                left: 10,
                                                width: 'auto'
                                            }}
                                            onClick={(e) => {
                                                setSaving(false);
                                                setNomeServico('');
                                                setSelectedSegmento('');
                                                changeStep(currentStep - 1, e)
                                            }}
                                            startIcon={<KeyboardBackspaceIcon />}
                                        >
                                            {currentStep === 0 ? '' : 'Anterior'}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grow>
                            {currentComponent &&
                                <Grow
                                    in
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...{ timeout: 1000 }}
                                >
                                    <Box
                                        sx={{
                                            mt: 3,
                                            minHeight: 200
                                        }}
                                    >
                                        {currentComponent}
                                    </Box>
                                </Grow>
                            }
                        </Stack>
                    </TabPanel>
                </TabContext>
            </Box >
        </Fragment >
    )
}

export default withUnidade(
    PermissaoHorario,
    "Permissão de Horário",
    csatList.cadastroHorario
);
