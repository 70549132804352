import React from 'react';
import {Table} from "react-bootstrap";
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import DropdownActionButton from "../DropdownActionButton/DropdownActionButton";
import Column from "./Column";
import SelectionColumn from "./SelectionColumn";
import { AuthContext } from '../../context/AuthContext';
import Row from "./Row";
import './styles.css'
import LoadingAnimation from "../LoadingAnimation/LoadigAnimation";

function CustomTable(
  {
    columns = [],
    data = [],
    configs = {},
    selected,
    onSelectionChange = (selection) => {},
    onChangeRow = (id, column, value) => {},
    onEditRow = (id) => {},
    isLoadingScroll = false,
    infinityLoadingFunc = () => {},
    isLoading = false,
    staticAction = false,
    detailsData = false
  }
) {
  const defaultOptions = {
    editable: false,
    selection: false,
    actions: [],
    onClickAction: (action, row) => { },
    editing: undefined, // array of ids
    ...configs
  }
  const defaultOptionsDetails = {
    editable: false,
    selection: false,
    actions: false,
    onClickAction: (action, row) => { },
    editing: undefined,
    configs: {}
  }
  const refCheckAll = React.useRef();
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [rowsEditing, setRowsEditing] = React.useState([]);
  React.useEffect(() => {
    const tableCells = document.querySelectorAll('.tableStyle tbody tr td');
    if (tableCells)
      tableCells.forEach((cell) => {
        const content = cell.querySelector('h6');


        if (content) {
          if (content.scrollWidth > cell.clientWidth) {
            cell.classList.add('overflow');
          }
        }
      });
  }, [data]);
  React.useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        infinityLoadingFunc()
      }
    })
    if (document.querySelector('#loadingInfinity'))
      intersectionObserver.observe(document.querySelector('#loadingInfinity'))

    return () => intersectionObserver.disconnect()
  }, [isLoadingScroll])
  React.useEffect(() => {
    if (defaultOptions.editing && defaultOptions.editing) {
      setRowsEditing(defaultOptions.editing);
    }
  }, [defaultOptions.editing]);

  React.useEffect(() => {
    if (selected) {
      setRowsSelected(selected);
    }
  }, [selected]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setRowsSelected(newSelecteds);
      if (onSelectionChange) {
        onSelectionChange(newSelecteds);
      }
      return;
    }
    setRowsSelected([]);
    if (onSelectionChange) {
      onSelectionChange([]);
    }
  };

  const handleEditRow = (row) => {
    if (onEditRow) {
      onEditRow(row);
    } else {
      setRowsEditing([...rowsEditing.filter(rid => rid !== row.id), row.id]);
      handleSelect(row)({ target: { checked: true } });
    }
  }

  const handleSelect = (row) => (event) => {
    const newSelecteds = [...rowsSelected.filter((id) => id !== row.id)];
    if (event.target.checked) {
      newSelecteds.push(row.id);
    }
    setRowsSelected(newSelecteds);
    if (onSelectionChange) {
      onSelectionChange(newSelecteds);
    }
    if (refCheckAll && refCheckAll.current) {
      if (newSelecteds.length === data.length) {
        refCheckAll.current.checked = true;
      } else if (newSelecteds.length > 0) {
        refCheckAll.current.indeterminate = true;
      } else {
        refCheckAll.current.checked = false;
        refCheckAll.current.indeterminate = false;
      }
    }
  };

  const handleChangeRow = (id, column, value) => {
    if (onChangeRow) {
      onChangeRow(id, column, value);
    }
  }

  const renderActions = (row) => {
    const actions = defaultOptions.actions.map(action => {
      if (typeof action === "function") {
        return action(row);
      }
      return action;
    });
    if (actions.length === 1) {
      const singleAction = actions[0];
      return (
        <td className={[staticAction ? "static" : "",].join(" ")}>
          {typeof singleAction === "function" ? (
            singleAction(row)
          ) : (
            <button onClick={(event) => defaultOptions.onClickAction(singleAction, row)}>
              {singleAction.label || 'Action'}
            </button>
          )}
        </td>
      );
    }
    return (
      <td>
        <DropdownActionButton
          options={actions}
          onClick={(event, action) => {
            defaultOptions.onClickAction(action, row)
          }}
        />
      </td>
    );
  }

  const renderSelection = row => {
    return (
      <TableCell padding="checkbox">
        <Checkbox
          checked={rowsSelected.includes(row.id)}
          sx={{
              color: 'var(--primary-color-disable)',
              '&.Mui-checked': {
                color: 'var(--primary-color-disable)',
              },
          }}
          onChange={handleSelect(row)}/>
      </TableCell>
    )
  }

  const renderActionColumn = () => {
    if (staticAction) return <th className='static-Action'>Ações</th>
    return <th>Ações</th>
  }
  

  return (
    <Table
      className={[staticAction ? "tableStyle staticTable" : "tableStyle",].join(" ")}
      bordered
      responsive
      style={{ backgroundColor: '#f5f5f5' }}
      id='customTableContainer'
    >
      <thead>
        <tr>
          {defaultOptions.selection && (
            <SelectionColumn
              innerRef={refCheckAll}
              checked={rowsSelected.length === data.length}
              onChange={handleSelectAllClick}
            />
          )}
          {columns.map(column => (
            <Column
              key={column.id}
              id={column.id}
              label={column.label}
              editable={column.editable}
            />
          ))}
          {!!defaultOptions.actions.length && renderActionColumn()}
        </tr>
      </thead>

      <tbody>
        {isLoading && (
          <tr>
            <td colSpan={columns.length + 1}>
              <LoadingAnimation/>
            </td>
          </tr>
        )}
        {!isLoading && data.map(row => (
          <>
            <Row
              key={row.id}
              data={row}
              editing={rowsEditing.includes(row.id)}
              onDoubleClick={() => handleEditRow(row)}
              onKeyPress={(event) => {
                if (event.key === "Escape") {
                  setRowsEditing(rowsEditing.filter(rid => rid !== row.id));
                }
              }}
              selection={defaultOptions.selection && renderSelection(row)}
              actions={!!defaultOptions.actions.length && renderActions(row)}
              columns={columns}
              isStaticAction={staticAction}
              isSelected={defaultOptions.selection ? rowsSelected.includes(row.id) : rowsSelected.push(row.id)}
              onChange={handleChangeRow}
              onClick={(event) => handleSelect(row)({
                ...event,
                target: {
                  ...event.target,
                  checked: true
                }
              })}
            />
            {detailsData && (
              <tr>
                <td colSpan={columns.length + 1} className="tableDetails">
                  <AuthContext.Consumer>
                    {({ appContext }) => {
                      const { tables } = appContext || {};
                      const detalhesData = row.detalhes
                      const colunasFinanceiro = tables[detalhesData.tipoColumn]?.columns || [];
                      return (
                        <div className={"row p-b-10 row-"+ row.id + " hideDetails"}>
                          <div className='p-10'>
                            <CustomTable
                              columns={colunasFinanceiro}
                              data={(detalhesData || [])}
                              configs={defaultOptionsDetails}
                            />
                          </div>
                        </div>
                      )
                    }}
                  </AuthContext.Consumer>
                </td>
              </tr>
            )}
          </>
        ))}
        {!isLoading && isLoadingScroll && (
          <tr>
            <td
              colSpan={columns.length + 1}
              className='LoadingRowAnimation'
              id='loadingInfinity'
            >
              <LoadingAnimation movableLoading={true} />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default CustomTable
