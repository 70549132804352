import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import SuccessCheckmark from "../sucess-checkmark/index";
interface Props {
    titleOption?: string;
    title: string;
    descOption?: string;
    desc: string;
    whichTitle?: boolean;
    whichDescription?: boolean;
    type?: string;
}
const infoIconPath = '/img/iconly/iconly-glass-info.svg';
const renderIcon = (type: string) => {
    switch (type) {
        case 'info':
            return <img alt="info-icon" src={infoIconPath} />;
        default:
            return <SuccessCheckmark />;
    }
}
const InfoComponent = (props: Props) => {
    const { titleOption, title, descOption, desc, whichTitle, whichDescription, type } = props;
    return (
        <Container maxWidth='lg'>
            <Grid
                alignItems='center'
                container
                sx={{
                    backgroundColor: 'var(--bs-gray-200)',
                    borderRadius: 1,
                    px: 4,
                    py: 8
                }}
            >
                <Grid xs={12} md={7}>
                    <Stack
                        sx={{
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography color='inherit' variant='h3' textAlign='center'>
                            {whichTitle ? title : titleOption || title}
                        </Typography>
                        <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                            {renderIcon(type || '')}
                        </Box>
                    </Stack>
                    <Typography
                        color='neutral.500'
                        sx={{ mt: 2 }}
                        variant='body2'
                        dangerouslySetInnerHTML={{ __html: whichDescription ? desc : descOption || desc }}
                    >
                    </Typography>
                </Grid>
                <Grid
                    md={5}
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'flex'
                        },
                        justifyContent: 'center'
                    }}
                >
                    {renderIcon(type || '')}
                </Grid>
            </Grid>
        </Container>
    )
}
export default InfoComponent;